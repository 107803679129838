import React from 'react';
import Button from '../Components/Button';
import Card from '../Components/Card';
import RageRoomMobile from '../Assets/Images/RageRoomMobile.png';

function StyleSheet() {
  return (
    <section>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>

     </section>
  );
}

export default StyleSheet;
